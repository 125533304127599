import React from 'react';
import hospitalsPic from "../../../../../../images/pages/docs/directorPreview/hospitals.png";
import ImageComponent from "../../../../../Tools/Image/ImageComponent";

class Hospital extends React.Component {
  render() {
    return (
      <div className={'hospital'}>
        <h2>Hospitals</h2>

        <p>A list of hospitals the medical company works with.</p>

        <div className="row">
          <div className="col-4">
            <ImageComponent
              pic={hospitalsPic}
              alt={`Hospitals Page`}
              class="img-fluid section-pic"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Hospital;
