import React from 'react';
import './App.css';
import Layout from '../Template/Layout/Layout'
import {firebaseApp} from "../../firebase/firebase";
import { getAnalytics, logEvent } from "firebase/analytics";

function App() {
  const analytics = getAnalytics(firebaseApp);
  logEvent(analytics, 'app-loaded');
  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
