import React from 'react';

class GettingStarted extends React.Component {
  render() {
    return (
      <div className={'getting-started'}>
        <h2>Getting started</h2>

        <p>
          First of all we need to fill the general data to have possibility to work with system MedCenter24. We suggest
          you to fill that info in this order:
        </p>

        <ul className={`mc-list`}>
          <li className="title">Reference information</li>
          <li><a href="#assistants" className="text-muted">Assistants</a> - companies-partners which support assurances. <code>Address Book &rarr; Assistants</code></li>
          <li><a href="#crc" className="text-muted">Countries</a> - countries where we operate. <code>GEO &rarr; Countries</code></li>
          <li><a href="#crc" className="text-muted">Regions</a> - regions where we operate. <code>GEO &rarr; Regions</code></li>
          <li><a href="#crc" className="text-muted">Cities</a> - cities where we operate. <code>GEO &rarr; Cities</code></li>
          <li className="title">Hospitals case</li>
          <li><a href="#hospitals" className="text-muted">Hospitals</a> - hospitals which could handle our patients. <code>GEO &rarr; Hospitals</code> <span className="text-muted">(hospitals case only)</span></li>
          <li className="title">Doctors case</li>
          <li><a href="#staff" className="text-muted">Staff</a> - doctors who work in the company <code>Doctors &rarr; Staff</code> <span className="text-muted">(doctors case only)</span></li>
          <li><span className="text-muted">Diagnostics</span> - that was discovered, diagnosed with acute bronchitis, catarrhal transient, etc. <code>Doctors &rarr; Diagnostics</code> </li>
          <li><span className="text-muted">Services</span> - Paid parameter: injection, hospitalization, services provided by doctor. <code>Doctors &rarr; Services</code> </li>
          <li><span className="text-muted">Surveys</span> - Inspection data: General condition relatively satisfactory. Heart Tones
            rhythmic, pathological noises, etc. <code>Doctors &rarr; Surveys</code> </li>
        </ul>
      </div>
    );
  }
}

export default GettingStarted;
