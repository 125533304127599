import React from 'react';
import doctorsPic from "../../../../../../images/pages/docs/directorPreview/doctors.png";
import ImageComponent from "../../../../../Tools/Image/ImageComponent";

class Doctors extends React.Component {
  render() {
    return (
      <div className={'doctors'}>
        <h2>Doctors</h2>

        <p>A list of doctors who work at the medical company.</p>
        <div className="row">
          <div className="col-4">
            <ImageComponent
              pic={doctorsPic}
              alt={`Doctors Page`}
              class="img-fluid section-pic"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Doctors;
