import React from 'react';
import { Link } from 'react-router-dom';
import './Main.css';
import logo from '../../../images/medicalCompany_1200.png';
import director from '../../../images/director_900.png';
import doctor from '../../../images/doctor_900.png';
import {FormattedMessage} from "react-intl";

class Main extends React.Component {
  render() {
    return (
        <div className="main-component">
          <section
            className={`section medcenterinfo align-item-center`}
          >
            <div className="container">
              <div className="row align-item-center">
                <div className="col-12 col-md-6">
                  <h2 className="title">
                    <FormattedMessage
                      id = "content.slogan"
                      defaultMessage="MedCenter makes your business <span>easier</span>."
                      values={{
                        span: (...chunks) => <span>{chunks}</span>
                      }}
                    />
                  </h2>
                  <h5 className="descr"><FormattedMessage
                    id = "content.slogan_descr"
                    defaultMessage="All processes in one place"
                  /></h5>
                  <Link to={`/demo`} className="btn"><FormattedMessage
                    id = "content.get_try"
                    defaultMessage="Get Try"
                  /></Link>
                </div>
                <div className="col-12 col-md-6">
                  <img src={logo} alt="medcenter24.com" className="img-fluid" />
                </div>
              </div>
            </div>
          </section>
          <section className={`section align-item-center`}>
            <div className="container">
              <div className="row align-item-center">
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-8 mx-auto">
                      <img
                        src={director}
                        alt="director.medcenter24.com"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className={`col-12 col-md-6 text`}>
                  <h1 className="title"><FormattedMessage
                    id = "content.directors"
                    defaultMessage="Directors"
                  />.</h1>
                  <p>
                    <FormattedMessage
                      id = "content.directors_desc"
                      defaultMessage="Part of the MedCenter APP that&#39;s in charge of working at
                    the director level. Here you can put up the financial part,
                    salaries, hospital and company fees. You can also set up the
                    system."
                    />
                  </p>
                  <Link className={`btn float-right`} to="/docs/director-preview"><FormattedMessage
                    id = "content.more"
                    defaultMessage="More"
                  /></Link>
                </div>
              </div>
            </div>
          </section>
          <section className="services">
            <h1 className="title text-center"><FormattedMessage
              id = "content.director_services"
              defaultMessage="Directors Services"
            /></h1>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="service">
                    <div className="icon">
                      <i className={`material-icons bigicon`}>group</i>
                    </div>
                    <h5 className="bolder"><FormattedMessage
                      id = "content.staff"
                      defaultMessage="Staff"
                    /></h5>
                    <p><FormattedMessage
                      id = "content.staff_desc"
                      defaultMessage="Doctors who work in the company."
                    /></p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="service">
                    <div className="icon">
                      <i className={`material-icons bigicon`}>trending_up</i>
                    </div>
                    <h5 className="bolder"><FormattedMessage
                      id = "content.statistic"
                      defaultMessage="Statistic"
                    /></h5>
                    <p><FormattedMessage
                      id = "content.statistic_desc"
                      defaultMessage="Salary statistics, statistics on assistance companies, doctors statistics..."
                    /></p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="service">
                    <div className="icon">
                      <i className={`material-icons bigicon`}>description</i>
                    </div>
                    <h5 className="bolder"><FormattedMessage
                      id = "content.documents"
                      defaultMessage="Documents"
                    /></h5>
                    <p><FormattedMessage
                      id = "content.documents_desc"
                      defaultMessage="Documentation. Patients passports, insurances, invoices. Companies reports and documents based
                    on the personal forms."
                    /></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={`section doctors align-item-center`}>
            <div className="container">
              <div className="row align-item-center">
                <div className={`col-12 col-md-6 text`}>
                  <h1 className="title"><FormattedMessage
                    id = "content.doctor"
                    defaultMessage="Doctor"
                  />.</h1>
                  <p><FormattedMessage
                    id = "content.doctor_desc"
                    defaultMessage="Doctors cabinet to create and control his cases, salaries and statistics."
                  /></p>
                  <Link className="btn float-right" to="/docs/doctor-preview"><FormattedMessage
                    id = "content.more"
                    defaultMessage="More"
                  /></Link>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-8 mx-auto">
                      <img
                        src={doctor}
                        alt="doctor.medcenter24.com"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="services">
            <h1 className="title text-center"><FormattedMessage
              id = "content.doctors_services"
              defaultMessage="Doctors Services"
            /></h1>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="service">
                    <div className="icon">
                      <i className={`material-icons bigicon`}>description</i>
                    </div>
                    <h5 className="bolder"><FormattedMessage
                      id = "content.cases"
                      defaultMessage="Cases"
                    /></h5>
                    <p className="text-muted"><FormattedMessage
                      id = "content.cases_desc"
                      defaultMessage="Cases that were or have to be processed by the doctor. Doctor can pickup assigned case, reject it or proceed."
                    /></p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="service">
                    <div className="icon">
                      <i className={`material-icons bigicon`}>monetization_on</i>
                    </div>
                    <h5 className="bolder"><FormattedMessage
                      id = "content.income"
                      defaultMessage="Income"
                    /></h5>
                    <p className="text-muted"><FormattedMessage
                      id = "content.income_desc"
                      defaultMessage="Doctor can see assigned prices to his services and check his statistic about his work."
                    /></p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="service">
                    <div className="icon">
                      <i className={`material-icons bigicon`}>person_pin</i>
                    </div>
                    <h5 className="bolder"><FormattedMessage
                      id = "content.personal_information"
                      defaultMessage="Personal Information"
                    /></h5>
                    <p className={`text-muted`}><FormattedMessage
                      id = "content.personal_information_desc"
                      defaultMessage="Quick way to control doctors information updated. Such as phone numbers, actual state and name."
                    /></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    );
  }
}

export default Main;
