import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Main from '../../Pages/Main/Main';
import Docs from '../../Pages/Docs/Docs';
import './Layout.css';
import bgTop from '../../../images/bg-top.png';
import Demo from "../../Pages/Demo/Demo";
import Info from "../../Pages/Docs/Pages/Info/Info";
import Installation from "../../Pages/Docs/Pages/Installation/Installation";
import Configuration from "../../Pages/Docs/Pages/Configuration/Configuration";
import Docker from "../../Pages/Docs/Pages/Docker/Docker";
import Homestead from "../../Pages/Docs/Pages/Homestead/Homestead";
import DirectorTech from "../../Pages/Docs/Pages/DirectorTech/DirectorTech";
import BackofficePreview from "../../Pages/Docs/Pages/BackofficePreview/BackofficePreview";
import DirectorPreview from "../../Pages/Docs/Pages/DirectorPreview/DirectorPreview";
import Telegram from "../../Pages/Docs/Pages/Telegram/Telegram";
import ReferralNumber from "../../Pages/Docs/Pages/DirectorPreview/Sections/ReferralNumber";
import CaseType from "../../Pages/Docs/Pages/DirectorPreview/Sections/CaseType";
import GettingStarted from "../../Pages/Docs/Pages/DirectorPreview/Sections/GettingStarted";
import Login from "../../Pages/Docs/Pages/DirectorPreview/Sections/Login";
import Assistant from "../../Pages/Docs/Pages/DirectorPreview/Sections/Assistant";
import Geo from "../../Pages/Docs/Pages/DirectorPreview/Sections/Geo";
import Hospital from "../../Pages/Docs/Pages/DirectorPreview/Sections/Hospital";
import Doctors from "../../Pages/Docs/Pages/DirectorPreview/Sections/Doctors";
import Cases from "../../Pages/Docs/Pages/DirectorPreview/Sections/Cases";
import PolicyModal from "../Modal/PolicyModal";
import Privacy from "../../Pages/Privacy/Privacy";
import License from "../../Pages/License/License";

class Layout extends React.Component {
    render() {
        return (
            <Router>
                <div>
                    <div className="top-bg">
                        <img src={bgTop} alt="Blue corner"/>
                    </div>
                    <Header/>
                    <Routes>
                        <Route exact path="/" element={<Main />}/>
                        <Route exact path="/demo" element={<Demo />}/>
                        <Route exact path="/privacy" element={<Privacy />}/>
                        <Route exact path="/license" element={<License />}/>
                        <Route path="/docs" element={<Docs />}>
                            <Route index element={<Info />} />
                            <Route path='installation' element={<Installation />} />
                            <Route path='configuration' element={<Configuration />} />
                            <Route path='docker' element={<Docker />} />
                            <Route path='homestead' element={<Homestead />} />
                            <Route path='director' element={<DirectorTech />} />
                            <Route path='backoffice-preview' element={<BackofficePreview />} />
                            <Route path='director-preview' element={<DirectorPreview />}>
                                <Route path="referral-number" element={<ReferralNumber />} />
                                <Route path="case-type" element={<CaseType />} />
                                <Route path="getting-started" element={<GettingStarted />} />
                                <Route path="login" element={<Login />} />
                                <Route path="assistant" element={<Assistant />} />
                                <Route path="geo" element={<Geo />} />
                                <Route path="hospitals" element={<Hospital />} />
                                <Route path="doctors" element={<Doctors />} />
                                <Route path="cases" element={<Cases />} />
                            </Route>
                            <Route path='telegram' element={<Telegram />} />
                        </Route>
                    </Routes>
                    <Footer/>
                    <PolicyModal />
                </div>
            </Router>
        );
    }
}

export default Layout;
