import React from 'react';

class CaseType extends React.Component {
  render() {
    return (
      <div className={'case-type'}>
        <h2>Case Types. Doctors or Hospitals Case</h2>

        <p>Each case has its own purpose. In the MedCenter24 we have 2 options for the case: Doctors case - means that
          doctor has visited the patient; and Hospitals case - means that patients sent to the hospital.</p>
      </div>
    );
  }
}

export default CaseType;
