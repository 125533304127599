import React from 'react';

class ReferralNumber extends React.Component {
  render() {
    return (
      <div className={'referral-number'}>
        <h2>Referral Number</h2>
        <p>Internal referral number - unique value assigned to the particular case. <br/>
          <strong>Example</strong>: <code>G0001-010117-TFF</code><br />
          <code>G</code> - Referral prefix of the assistance (Global voyager assistance)<br />
          <code>0001</code> - number in order (from the begin of this year in order to current assistance cases) <br/>
          <code>010117</code> - date (dmY - dayMonthYear) <br/>
          <code>D</code> - Day/Night/Weekend <br/>
          <code>FA</code> - Doctor initials = Doctor referral prefix (Foster Abigail) / Hospital referral prefix (SP - Sant Paolo Hospital) <br/>
          <strong>NA</strong> - this is default value when we don't know what should be used there (creating case without doctor or without assignment to assistance)</p>
      </div>
    );
  }
}

export default ReferralNumber;
