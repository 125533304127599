import React from 'react';
// import ScrollableAnchor from 'react-scrollable-anchor';
// import {configureAnchors} from 'react-scrollable-anchor';
import AdminPanelPic from "../../../../../images/pages/docs/telegram/admin-panel.png";
import Mc24BotPic from "../../../../../images/pages/docs/telegram/mc24bot.png";
import InvitePic from "../../../../../images/pages/docs/telegram/invites.png";
import ResponseInitializedPic from "../../../../../images/pages/docs/telegram/responsetoinitialized.png";
import NewCaseNotificationPic from "../../../../../images/pages/docs/telegram/caseNotification.png";
import {Link} from "react-router-dom";
import ImageComponent from "../../../../Tools/Image/ImageComponent";

class Telegram extends React.Component {
  render() {

    // configureAnchors({offset: -100, scrollDuration: 200});

    return (
      <div className="telegram-component">
        <div className="row justify-content-center">
          <div className="col-12">
            <h1>Telegram</h1>

            <p className="text-muted">
              Telegram integration allows us to control activities with <code>telegram</code> messenger. Doctor receives
              new message when new case assigned to him.
            </p>

            <ul className={`mc-list`}>
              <li>
                <Link to="/docs/telegram#configuration" className="blacklink">Configuration</Link>
              </li>
              <li>
                <Link to="/docs/telegram#example" className={`blacklink`}>Example</Link>
              </li>
            </ul>

            {/*<ScrollableAnchor id={'configuration'}>*/}
              <h2>Configuration</h2>
            {/*</ScrollableAnchor>*/}
            <h3>Bot for the application</h3>
            <h4>Create new telegram bot</h4>
            <ul className={`mc-list`}>
              <li>Goto <a href="https://telegram.me/botfather">BotFather</a></li>
              <li><code>/start</code></li>
              <li><code>/newbot</code> (enter bot name on request and username, Example: MedCenter24, MedCenter24bot)
              </li>
              <span className="text-muted">Not Required</span>
              <li>Description for the bot: <code>/setdescription</code></li>
              <li>To change a profile picture: <code>/setuserpic</code></li>
              <li>More commands to control your bot: <code>/help</code></li>
            </ul>
            <h4>Connect bot to the application</h4>
            <p>To connect new bot to the MedCenter24 application you need a token from the telegram. We have to add this
              configurable parameters: <br/>
              <code>CUSTOMER_NAME=MedCenter24</code> <br/>
              <code>TELEGRAM_BOT_TOKEN='token to access telegrams HTTP API'</code> <br/>
              <code>TELEGRAM_WEBHOOK_PREFIX="webhook123secret"</code> <br/>
              <code>TELEGRAM_WEBHOOK_URL="https://backoffice.medcenter24.com/telegram"</code>
            </p>
            <p>
              You can check telegram bot settings from the backoffice: <a
              href="https://backoffice.medcenter24.com/admin/preview/telegram"
              target={`_blank`}>https://backoffice.medcenter24.com/admin/preview/telegram</a>
            </p>
            <p>
              <b>Please NOTE</b><br/>
              `telegram` - is a predefined controller for the telegrams API. You have to setup your webhook URL from the
              backoffices page:<code>https://medcenter.dhv24.com/telegram/[yoursecretprefix]</code> and press "Set new
              webhook" button. That will send new
              webhook to your telegram bot and after that telegram bot will send all messages and commands to the
              webhook URL.
            </p>
            <h3>Connect Telegram ID to the user</h3>
            <p>To define your or doctors <b>TelegramID</b> you can find <b>@userinfobot</b> in the telegram and this
              will show your ID.</p>
            <ul className={`mc-list`}>
              <li>Create new invite in the backoffice <b>Invites</b> for the user and send it to the user</li>
              <li>Go to the MedCenter24bot chat in <b>telegram</b></li>
              <li>Write <code>/start</code> and press <b>Enter</b></li>
              <li>To the response on the message you have to send invitation code from the backoffice</li>
            </ul>

            {/*<ScrollableAnchor id={'installing'}>*/}
              <h2>Telegram Example</h2>
            {/*</ScrollableAnchor>*/}
            <p>
              How it works demo.
            </p>

            <div className="gallery">
              <div className="row">
                <div className="col-3">
                  <ImageComponent
                    pic={InvitePic}
                    alt={`Create invite for user`}
                    class="img-fluid section-pic"
                  />
                </div>
                <div className="col-3">
                  <ImageComponent
                    pic={Mc24BotPic}
                    alt={`Telegram Bot`}
                    class="img-fluid section-pic"
                  />
                </div>
                <div className="col-3">
                  <ImageComponent
                    pic={ResponseInitializedPic}
                    alt={`When bot knows you`}
                    class="img-fluid section-pic"
                  />
                </div>
                <div className="col-3">
                  <ImageComponent
                    pic={NewCaseNotificationPic}
                    alt={`Automatic message on the new case assignment`}
                    class="img-fluid section-pic"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <ImageComponent
                    pic={AdminPanelPic}
                    alt={`Telegram Admin Page`}
                    class="img-fluid section-pic"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Telegram;
