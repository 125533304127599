import React from 'react';
import './Demo.css';
import createCaseFilled from '../../../images/pages/docs/directorPreview/case/form/createCaseFilled.png';
import telegramCase from '../../../images/pages/docs/doctorPreview/case/form/TelegramCase.jpg';
import doctorCaseFilled from '../../../images/pages/docs/doctorPreview/case/form/caseFilled.png';
import directorFilledCase from '../../../images/pages/docs/directorPreview/case/form/directorFilledForm.png';
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import ImageComponent from "../../Tools/Image/ImageComponent";

class Demo extends React.Component {
  render() {
    return (
      <div className="main-component p-top">
        <section className={`section align-item-center`}>
          <div className="container">
            <div className="row align-item-center">
              <div className="col-12 col-md-6 text">
                <h1 className="title"><sup className="text-muted small">1</sup> <FormattedMessage
                  id="content.create_new_case"
                  defaultMessage="Create New Case"
                />.</h1>
                <p className="text-muted"><FormattedMessage
                  id="content.create_new_case_desc"
                  defaultMessage="The fastest mechanism for creating a new case. There are no mandatory
                     fields, just fill in the fields that you consider necessary."
                /></p>
                <Link className={`btn float-right`} to="/docs/director-preview/cases#top"><FormattedMessage
                  id="content.more"
                  defaultMessage="More"
                /></Link>
              </div>
              <div className="col-12 col-md-6">
                <ImageComponent
                  pic={createCaseFilled}
                  alt={`Director's empty case form`}
                  class="img-fluid section-pic"
                />
              </div>
            </div>
          </div>
        </section>

        <section className={`section align-item-center`}>
          <div className="container">
            <div className="row align-item-center">
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-8 mx-auto">
                    <ImageComponent
                      pic={telegramCase}
                      alt={`Doctor receives message in telegram`}
                      class="img-fluid section-pic"
                    />
                  </div>
                </div>
              </div>
              <div className={`col-12 col-md-6 text`}>
                <h1 className="title"><sup className="text-muted small">2</sup> <FormattedMessage
                  id="content.telegram_bot"
                  defaultMessage="Telegram Bot"
                />.</h1>
                <p className="text-muted">
                  <FormattedMessage
                    id="content.telegram_bot_desc"
                    defaultMessage="The system automatically sends a notification to doctors about a new case. The notification includes all the information you need to process it. The interface also includes a case acceptance button, so that the director can keep track of how quickly the physician has responded to the call, and to avoid major downtime or missed calls."
                  />
                </p>
                <Link className={`btn float-right`} to="/docs/doctor-preview/cases#top"><FormattedMessage
                  id="content.more"
                  defaultMessage="More"
                /></Link>
              </div>
            </div>
          </div>
        </section>

        <section className={`section align-item-center`}>
          <div className="container">
            <div className="row align-item-center">
              <div className="col-12 col-md-6 text">
                <h1 className="title"><sup className="text-muted small">3</sup> <FormattedMessage
                  id="content.doctors_form"
                  defaultMessage="Doctor's Form"
                />.</h1>
                <p className="text-muted">
                  <FormattedMessage
                    id="content.doctors_form_desc"
                    defaultMessage="The doctor fills out a minimal set of fields without being distracted by other."
                  />
                </p>
                <Link className={`btn float-right`} to="/docs/doctor-preview/cases#top"><FormattedMessage
                  id="content.more"
                  defaultMessage="More"
                /></Link>
              </div>
              <div className={`col-12 col-md-6`}>
                <div className="row">
                  <div className="col-8 mx-auto">
                    <ImageComponent
                      pic={doctorCaseFilled}
                      alt={`Doctor's filled form`}
                      class="img-fluid section-pic"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={`section doctors align-item-center`}>
          <div className="container">
            <div className="row align-item-center">
              <div className={`col-12 col-md-6`}>
                <div className="row">
                  <div className="col-8 mx-auto">
                    <ImageComponent
                      pic={directorFilledCase}
                      alt={`Director's filled form`}
                      class="img-fluid section-pic"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 text">
                <h1 className="title"><sup className="text-muted small">4</sup> <FormattedMessage
                  id="content.close_case"
                  defaultMessage="Close Case"
                />.</h1>
                <p className="text-muted">
                  <FormattedMessage
                    id="content.director_last_step_desc"
                    defaultMessage="The director has only to check the automatically calculated amounts and, if necessary, send invoices for payment and close the case."
                  />
                </p>
                <Link className={`btn float-right`} to="/docs/director-preview/cases#top"><FormattedMessage
                  id="content.more"
                  defaultMessage="More"
                /></Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Demo;
