import React from 'react';
import './DirectorTech.css';
// import {configureAnchors} from "react-scrollable-anchor";

class DirectorTech extends React.Component {
  render() {

    // configureAnchors({offset: -100, scrollDuration: 200});

    return (
      <div className={`tech-info`}>
        Tech info
      </div>
    );
  }
}

export default DirectorTech;
