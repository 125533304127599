import React from 'react';
import assistantsPic from "../../../../../../images/pages/docs/directorPreview/assistants.png";
import ImageComponent from "../../../../../Tools/Image/ImageComponent";

class Assistant extends React.Component {
  render() {
    return (
      <div className={'assistants'}>
        <h2>Assistants</h2>

        <p>List of assistants.</p>
        <div className="col-4">
          <ImageComponent
            pic={assistantsPic}
            alt={`Assistants Page`}
            class="img-fluid section-pic"
          />
        </div>
      </div>
    );
  }
}

export default Assistant;
