import React, {useEffect} from 'react';
import {Link, Outlet, useLocation} from 'react-router-dom';
import './DirectorPreview.css';
import {FormattedMessage} from "react-intl";

const DirectorPreview = () => {
    const location = useLocation();

    const scrollIntoViewWithOffset = (selector, offset) => {
        window.scrollTo({
            behavior: 'smooth',
            top:
                document.querySelector(selector).getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                offset,
        });
    };

    const scrollToTop = () => {
        window.scrollTo({behavior: 'smooth', top: 0});
    }

    useEffect(() => {

        if (location.pathname !== '/docs/director-preview') {
            scrollIntoViewWithOffset('#director-preview-content', 100);
            // myRef.current.scrollIntoView();
        } else {
            scrollToTop();
        }
    }, [location]);

    return (

        <div className={`tech-info`} id={`doctor-preview-container`}>
            <h1><FormattedMessage
                id="docs.director_preview"
                defaultMessage="Director. Preview."
            /></h1>
            <p className={`text-muted`}><FormattedMessage
                id="docs.director_preview_desc"
                defaultMessage="Directors part of the MedCenter24."
            /></p>

            <ul className={`mc-list`}>
                <li className="title"><FormattedMessage
                    id="docs.general_information"
                    defaultMessage="General Information"
                /></li>
                <li>
                    <Link to='/docs/director-preview/referral-number' className="blacklink"><FormattedMessage
                        id="docs.referral_number"
                        defaultMessage="Referral Number"
                    /></Link>
                </li>
                <li>
                    <Link to='/docs/director-preview/case-type' className="blacklink"><FormattedMessage
                        id="docs.case_type"
                        defaultMessage="Case Type"
                    /></Link>
                </li>
                <li>
                    <Link to='/docs/director-preview/getting-started' className="blacklink"><FormattedMessage
                        id="docs.getting_started"
                        defaultMessage="Getting started"
                    /></Link>
                </li>
                <li className="title"><FormattedMessage
                    id="docs.sections"
                    defaultMessage="Sections"
                /></li>
                <li>
                    <Link to='/docs/director-preview/login' className="blacklink"><FormattedMessage
                        id="docs.login"
                        defaultMessage="Login"
                    /></Link>
                </li>
                <li>
                    <Link to='/docs/director-preview/assistant' className="blacklink"><FormattedMessage
                        id="docs.assistants"
                        defaultMessage="Assistants"
                    /></Link>
                </li>
                <li>
                    <Link to='/docs/director-preview/geo' className="blacklink"><FormattedMessage
                        id="docs.geo"
                        defaultMessage="Geo"
                    /></Link>
                </li>
                <li>
                    <Link to='/docs/director-preview/hospitals' className="blacklink"><FormattedMessage
                        id="docs.hospitals"
                        defaultMessage="Hospitals"
                    /></Link>
                </li>
                <li>
                    <Link to='/docs/director-preview/doctors' className="blacklink"><FormattedMessage
                        id="docs.doctors"
                        defaultMessage="Doctors"
                    /></Link>
                </li>
                <li>
                    <Link to='/docs/director-preview/cases' className="blacklink"><FormattedMessage
                        id="docs.cases"
                        defaultMessage="Cases"
                    /></Link>
                </li>
            </ul>

            <div className="director-preview-content" id={`director-preview-content`}>
                <Outlet/>
            </div>
        </div>
    )
}

export default DirectorPreview;
