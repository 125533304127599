import React from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

class Info extends React.Component {
  render() {
    return (
      <div className="info-component">
        <div className="row justify-content-center">
          <div className="col-12">
            <h1>Documentation</h1>
            <nav className="">
              <nav className="nav nav-pills flex-column">
                <Link to="/docs" className="nav-link"><FormattedMessage
                  id = "docs.content"
                  defaultMessage="Content"
                /></Link>
                <b><FormattedMessage
                  id = "docs.getting_started"
                  defaultMessage="Getting Started"
                /></b>
                <Link to="/docs/installation" className="nav-link"><FormattedMessage
                  id = "docs.installation"
                  defaultMessage="Installation"
                /></Link>
                <ul className={`mc-list`}>
                  <li>
                    <Link to='/docs/installation#requirements' className="blacklink"><FormattedMessage
                      id = "docs.server_requirements"
                      defaultMessage="Server Requirements"
                    /></Link>
                  </li>
                  <li>
                    <Link to="/docs/installation#installing" className={`blacklink`}><FormattedMessage
                      id = "docs.installing_medcenter24"
                      defaultMessage="Installing MedCenter24"
                    /></Link>
                  </li>
                  <li>
                    <Link to="/docs/installation#configuration" className={`blacklink`}><FormattedMessage
                      id = "docs.web_server_configuration"
                      defaultMessage="Web Server Configuration"
                    /></Link>
                  </li>
                </ul>
                <Link to="/docs/configuration" className="nav-link"><FormattedMessage
                  id = "docs.app_configuration"
                  defaultMessage="Application Configuration"
                /></Link>
                <ul className={`mc-list`}>
                  <li>
                    <Link to="/docs/installation#desc" className="blacklink"><FormattedMessage
                      id = "docs.general_information"
                      defaultMessage="General information"
                    /></Link>
                  </li>
                  <li>
                    <Link to="/docs/installation#env" className={`blacklink`}><FormattedMessage
                      id = "docs.env_configuration_file"
                      defaultMessage=".Env configuration file"
                    /></Link>
                  </li>
                  <li>
                    <Link to="/docs/installation#config" className={`blacklink`}><FormattedMessage
                      id = "docs.generis_configuration_file"
                      defaultMessage="Generis Configuration file"
                    /></Link>
                  </li>
                </ul>
                <Link to="/docs/docker" className="nav-link">Docker</Link>
                <Link to="/docs/homestead" className="nav-link">Homestead</Link>
                <b><FormattedMessage
                  id = "docs.app_structure"
                  defaultMessage="Application Structure"
                /></b>
                <Link to={`/docs/director`} className="nav-link"><FormattedMessage
                  id = "content.director"
                  defaultMessage="Director"
                /></Link>
                <Link to={`/docs/doctor`} className="nav-link"><FormattedMessage
                  id = "content.doctor"
                  defaultMessage="Doctor"
                /></Link>
                <Link to={`/docs/api`} className="nav-link">API</Link>
                <Link to={`/docs/backoffice`} className="nav-link"><FormattedMessage
                  id = "docs.back_office"
                  defaultMessage="Back Office"
                /></Link>
                <b><FormattedMessage
                  id = "docs.tools"
                  defaultMessage="Tools"
                /></b>
                <Link to={`/docs/telegram`} className="nav-link"><FormattedMessage
                  id = "docs.telegram"
                  defaultMessage="Telegram"
                /></Link>
                <b><FormattedMessage
                  id = "docs.how_it_works"
                  defaultMessage="How it works"
                /></b>
                <Link to={`/docs/director-preview`} className="nav-link"><FormattedMessage
                  id = "content.director"
                  defaultMessage="Director"
                /></Link>
                <ul className={`mc-list`}>
                  <li className="title"><FormattedMessage
                    id = "docs.general_information"
                    defaultMessage="General Information"
                  /></li>
                  <li>
                    <Link to='/docs/director-preview/referral-number' className="blacklink"><FormattedMessage
                      id = "docs.referral_number"
                      defaultMessage="Referral Number"
                    /></Link>
                  </li>
                  <li>
                    <Link to='/docs/director-preview/case-type' className="blacklink"><FormattedMessage
                      id = "docs.case_type"
                      defaultMessage="Case Type"
                    /></Link>
                  </li>
                  <li>
                    <Link to='/docs/director-preview/getting-started' className="blacklink"><FormattedMessage
                      id = "docs.getting_started"
                      defaultMessage="Getting started"
                    /></Link>
                  </li>
                  <li className="title"><FormattedMessage
                    id = "docs.sections"
                    defaultMessage="Sections"
                  /></li>
                  <li>
                    <Link to='/docs/director-preview/login' className="blacklink"><FormattedMessage
                      id = "docs.login"
                      defaultMessage="Login"
                    /></Link>
                  </li>
                  <li>
                    <Link to='/docs/director-preview/assistant' className="blacklink"><FormattedMessage
                      id = "docs.assistants"
                      defaultMessage="Assistants"
                    /></Link>
                  </li>
                  <li>
                    <Link to='/docs/director-preview/geo' className="blacklink"><FormattedMessage
                      id = "docs.geo"
                      defaultMessage="Geo"
                    /></Link>
                  </li>
                  <li>
                    <Link to='/docs/director-preview/hospitals' className="blacklink"><FormattedMessage
                      id = "docs.hospitals"
                      defaultMessage="Hospitals"
                    /></Link>
                  </li>
                  <li>
                    <Link to='/docs/director-preview/doctors' className="blacklink"><FormattedMessage
                      id = "docs.doctors"
                      defaultMessage="Doctors"
                    /></Link>
                  </li>
                  <li>
                    <Link to='/docs/director-preview/cases' className="blacklink"><FormattedMessage
                      id = "docs.cases"
                      defaultMessage="Cases"
                    /></Link>
                  </li>
                </ul>
                <Link to={`/docs/doctor-preview`} className="nav-link"><FormattedMessage
                  id = "content.doctor"
                  defaultMessage="Doctor"
                /></Link>
                <Link to={`/docs/backoffice-preview`} className="nav-link"><FormattedMessage
                  id = "docs.back_office"
                  defaultMessage="Back Office"
                /></Link>
              </nav>
            </nav>

          </div>
        </div>
      </div>
    );
  }
}

export default Info;
