import React from 'react';
import './Docker.css';

class Docker extends React.Component {
  render() {
    return (
      <div className="homestead-component">
        <div className="content">
          <h1>Docker</h1>
          -1. Описание того, зачем это нужно и что это делает
          0. Установка mc/docker from github
          1. Установка докера и докер композа
          2. Установка траефика
          3. Настройка сертификатов через mcert
          4. Запуск докер-композа
          5. Makefile description
        </div>
      </div>
    );
  }
}

export default Docker;
