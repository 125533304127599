import React, {useContext, useEffect, useState} from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import './Navigation.css';
import {Context} from "../../Tools/Translate/Wrapper";
import {useIntl} from "react-intl";

const Navigation = () => {
    const [state, setState] = useState({activeClass: 'top'});
    const context = useContext(Context);

    const scrollHandler = () => {
        let activeClass = 'stick';
        if (window.scrollY <= 30) {
            activeClass = 'top';
        }
        setState({activeClass});
    };

    const getSelectedLang = (code) => {
        const selectedLang = localStorage.getItem('lang');

        const languages = ['es', 'ru', 'uk-UA', 'en'];
        if (selectedLang && languages.indexOf(selectedLang)) {
            code = selectedLang;
        }

        return getLang(code);
    }

    const getLang = (code) => {
        switch (code) {
            case 'ru':
                return 'Ру';
            case 'uk-UA':
                return 'Укр';
            case 'en':
            case 'es':
            default:
                return code;
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        }
    });

    return (
        <Navbar collapseOnSelect expand="lg" fixed="top" className={`mcnavbar ${state.activeClass}`}>
            <Link to="/">.MedCenter24</Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    {/*<Link to="#features">
            <span className="blacklink">Features</span>
          </Link>*/}
                </Nav>
                <Nav className="mcnav">
                    <Link to="/demo" className="blacklink">
                        <span className="blacklink">{useIntl().formatMessage({
                            id: 'content.demo',
                            defaultMessage: 'Demo',
                            description: 'Demo preview'
                        })}</span>
                    </Link>
                    <Link to="/docs">
                        <span className="mcbtn">{useIntl().formatMessage({
                            id: 'content.documentation',
                            defaultMessage: 'Documentation'
                        })}</span>
                    </Link>

                    {/*<Link to="/about">
                        <span className="blacklink">About</span>
                      </Link>*/}
                    <NavDropdown title={getSelectedLang(context.locale)} id="nav-dropdown" onSelect={context.selectLanguage}>
                        <NavDropdown.Item eventKey="en">{getLang('en')}</NavDropdown.Item>
                        <NavDropdown.Item eventKey="es">{getLang('es')}</NavDropdown.Item>
                        <NavDropdown.Item eventKey="uk-UA">{getLang('uk-UA')}</NavDropdown.Item>
                        <NavDropdown.Item eventKey="ru">{getLang('ru')}</NavDropdown.Item>
                    </NavDropdown>
                </Nav>

            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation;
