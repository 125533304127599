import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import Wrapper from './components/Tools/Translate/Wrapper';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <StrictMode>
        <Wrapper>
            <App/>
        </Wrapper>
    </StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
