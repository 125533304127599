import React from 'react';
import {Link} from "react-router-dom";
import casesListPic from "../../../../../../images/pages/docs/directorPreview/caseList.png";
import newCasePic from "../../../../../../images/pages/docs/directorPreview/newCase.png";
import ImageComponent from "../../../../../Tools/Image/ImageComponent";

class Cases extends React.Component {
  render() {
    return (
      <div className={'cases'}>
        <h2>Cases</h2>

        <p className={`text-muted`}>This is the main page of the system which provides us an interface to control all
          MedCenters cases.</p>

        <h3>Case creation</h3>

        <p>Case creation form is smaller than for the full case preview, because we don't know and don't need to know
          a lot of information. So on the case creation page we can see general fields for the case. All the cases have
          <code>Assistant</code> that responsible for the case and sent us <code>Application</code> with the patient
          information. Also we have to fill <code>Assistant Referral Number</code> - unique identifier of the case for the
          assistant company (their internal identifier). Field <code>Referral Number</code> - it is unique internal value
          to mark the case and it would be better to leave this value an empty, then system generates this value on the fly
          according to these rules: <Link to={`#refnum`}>Referral Number Rules</Link>. At this step we also can add
          <code>Symptoms</code> of the patient, we can choose or create a patient and we can fill patients data</p>

        <h3>Doctors case</h3>

        <div className="row">
          <div className="col-4">
            <ImageComponent
              pic={casesListPic}
              alt={`Cases Page`}
              class="img-fluid section-pic"
            />
          </div>
          <div className="col-4">
            <ImageComponent
              pic={newCasePic}
              alt={`New Case Page`}
              class="img-fluid section-pic"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Cases;
