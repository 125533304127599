import React from 'react';
import countryPic from "../../../../../../images/pages/docs/directorPreview/country.png";
import regionPic from "../../../../../../images/pages/docs/directorPreview/region.png";
import cityPic from "../../../../../../images/pages/docs/directorPreview/city.png";
import ImageComponent from "../../../../../Tools/Image/ImageComponent";

class Geo extends React.Component {
  render() {
    return (
      <div className={'geo'}>
        <h2>Country. Region. City.</h2>

        <p>Geo Position.</p>

        <div className="row">
          <div className="col-4">
            <ImageComponent
              pic={countryPic}
              alt={`Country Page`}
              class="img-fluid section-pic"
            />
          </div>
          <div className="col-4">
            <ImageComponent
              pic={regionPic}
              alt={`Region Page`}
              class="img-fluid section-pic"
            />
          </div>
          <div className="col-4">
            <ImageComponent
              pic={cityPic}
              alt={`City Page`}
              class="img-fluid section-pic"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Geo;
