import React, {useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import './PolicyModal.css';
import {Link, useLocation} from "react-router-dom";

const PolicyModal = () => {
    const [state, setState] = useState({dontShowPolicy: false});
    const location = useLocation();

    const getState = () => {
        return !!localStorage.getItem('policyState');
    }

    function acceptPrivacy() {
        localStorage.setItem('policyState', true);
        setState({dontShowPolicy: true});
    }

    useEffect(() => {
        setState({dontShowPolicy: getState()});
    }, []);

    useEffect(() => {
        if (location.pathname === '/privacy') {
            setState({dontShowPolicy: true});
        } else {
            setState({dontShowPolicy: getState()});
        }
    }, [location]);

    const renderPolicy = () => (
        state.dontShowPolicy
        ? null
        : <div className={`policy-square`} id={`privacy-policy`}>
                <div className={`policy-container`}>
                    <div className={`text`}>
                        <h3><FormattedMessage
                            id="content.privacy"
                            defaultMessage="Privacy"
                        /></h3>
                        <p><FormattedMessage
                            id="content.privacy_short"
                            defaultMessage="Privacy Short Text"
                        /></p>

                        <div className="row">
                            <div className="col-7">
                                <button className={`btn btn-info`} onClick={acceptPrivacy}><FormattedMessage
                                    id="content.privacy_accept"
                                    defaultMessage="Accept All Cookies"
                                /></button></div>
                            <div className="col-5"><Link to={`privacy`}><FormattedMessage
                                id="content.privacy_policy"
                                defaultMessage="Privacy Policy"
                            /></Link></div>
                        </div>
                    </div>
                </div>
            </div>
    );

    return renderPolicy();
}

export default PolicyModal;
