import React, {useEffect} from 'react';
import {Link, Outlet, useLocation} from 'react-router-dom';
import './Docs.css';
// import Info from './Pages/Info/Info'
// import Installation from './Pages/Installation/Installation';
// import Configuration from './Pages/Configuration/Configuration';
// import Homestead from './Pages/Homestead/Homestead';
// import DirectorTech from './Pages/DirectorTech/DirectorTech';
// import BackofficePreview from "./Pages/BackofficePreview/BackofficePreview";
// import DirectorPreview from "./Pages/DirectorPreview/DirectorPreview";
// import Telegram from "./Pages/Telegram/Telegram";
// import Docker from "./Pages/Docker/Docker";
import {FormattedMessage} from "react-intl";

const Docs = () => {
    const location = useLocation();
    useEffect(() => {
        if (!location.pathname.startsWith('/docs/director-preview')) {
            window.scrollTo({behavior: 'smooth', top: 0});
        }
    }, [location]);

    return (
        <div className="docs-container">
            <div className="docs-component">
                <div className="navbar-docs-col">
                    <nav className="">
                        <nav className="nav nav-pills flex-column">
                            <Link to="/docs" className="nav-link"><FormattedMessage
                                id="docs.content"
                                defaultMessage="Content"
                            /></Link>
                            <b><FormattedMessage
                                id="docs.getting_started"
                                defaultMessage="Getting Started"
                            /></b>
                            <Link to="/docs/installation" className="nav-link"><FormattedMessage
                                id="docs.installation"
                                defaultMessage="Installation"
                            /></Link>
                            <Link to="/docs/configuration" className="nav-link"><FormattedMessage
                                id="docs.configuration"
                                defaultMessage="Configuration"
                            /></Link>
                            {/*<Link to="/docs/docker" className="nav-link">Docker</Link>*/}
                            <Link to="/docs/homestead" className="nav-link">Homestead</Link>
                            {/*<b><FormattedMessage*/}
                            {/*  id = "docs.structure"*/}
                            {/*  defaultMessage="Structure"*/}
                            {/*/></b>*/}
                            {/*<Link to={`/docs/director`} className="nav-link"><FormattedMessage*/}
                            {/*  id = "content.director"*/}
                            {/*  defaultMessage="Director"*/}
                            {/*/></Link>*/}
                            {/*<Link to={`/docs/doctor`} className="nav-link"><FormattedMessage*/}
                            {/*  id = "content.doctor"*/}
                            {/*  defaultMessage="Doctor"*/}
                            {/*/></Link>*/}
                            {/*<Link to={`/docs/api`} className="nav-link">API</Link>*/}
                            {/*<Link to={`/docs/backoffice`} className="nav-link"><FormattedMessage*/}
                            {/*  id = "docs.back_office"*/}
                            {/*  defaultMessage="Back Office"*/}
                            {/*/></Link>*/}
                            <b><FormattedMessage
                                id="docs.tools"
                                defaultMessage="Tools"
                            /></b>
                            <Link to={`/docs/telegram`} className="nav-link"><FormattedMessage
                                id="docs.telegram"
                                defaultMessage="Telegram"
                            /></Link>
                            <b><FormattedMessage
                                id="docs.how_it_works"
                                defaultMessage="How it works"
                            /></b>
                            <Link to={`/docs/director-preview`} className="nav-link"><FormattedMessage
                                id="content.director"
                                defaultMessage="Director"
                            /></Link>
                            {/*<Link to={`/docs/doctor-preview`} className="nav-link"><FormattedMessage*/}
                            {/*  id = "content.doctor"*/}
                            {/*  defaultMessage="Doctor"*/}
                            {/*/></Link>*/}
                            <Link to={`/docs/backoffice-preview`} className="nav-link"><FormattedMessage
                                id="docs.back_office"
                                defaultMessage="Back Office"
                            /></Link>
                        </nav>
                    </nav>
                </div>
                <div className="docs-content">
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default Docs;
