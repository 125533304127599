import React from 'react';
import { Link } from 'react-router-dom';
import './BackofficePreview.css';
// import ScrollableAnchor, {configureAnchors} from "react-scrollable-anchor";
import loginPic from '../../../../../images/pages/docs/backofficePreview/login.png';
import usersPic from '../../../../../images/pages/docs/backofficePreview/users.png';
import createUserPic from '../../../../../images/pages/docs/backofficePreview/userEditor.png';
import ImageComponent from "../../../../Tools/Image/ImageComponent";

class BackofficePreview extends React.Component {
  render() {

    // configureAnchors({offset: -100, scrollDuration: 200});

    return (
      <div className={`tech-info`}>
        <h1>Back Office. Preview.</h1>
        <p className={`text-muted`}>The point to start work with MedCenter24. Examples provided from the
          demo site: <Link to={`https://backoffice.medcenter24.com/`}>BackOffice.MedCenter24.com</Link>.</p>
        <p><span className={`text-muted`}>Homestead link: </span><a className={`blacklink`} href="http://backoffice.mc24/admin">http://backoffice.mc24/</a></p>

        <ul className={`mc-list`}>
          <li>
            <a href='#login' className="blacklink">Login</a>
          </li>
          <li>
            <a href='#users' className="blacklink">Users</a>
          </li>
        </ul>

        {/*<ScrollableAnchor id={'login'}>*/}
          <h2>Login</h2>
        {/*</ScrollableAnchor>*/}
        <p><span className={`text-muted`}>Homestead link: </span><a className={`blacklink`} href="http://backoffice.mc24/login">http://backoffice.mc24/login</a></p>
        <p>
          With Homestead environment you could try default login: <b className={`text-muted`}>admin@example.com</b> and
          password: <b className={`text-muted`}>secret</b>. Or you can create new admin user:
        </p>
        <pre><code>php artisan user:add admin@user.admin login,admin password</code></pre>

        <div className="gallery">
          <div className="row">
            <div className="col-4">
              <ImageComponent
                pic={loginPic}
                alt={`Login Page`}
                class="img-fluid section-pic"
              />
            </div>
          </div>
        </div>

        {/*<ScrollableAnchor id={'users'}>*/}
          <h2>Users</h2>
        {/*</ScrollableAnchor>*/}
        <p><span className={`text-muted`}>Homestead link: </span><a className={`blacklink`} href="http://backoffice.mc24/admin/users">http://backoffice.mc24/admin/users</a></p>
        <p>
          On the <span className={`text-muted`}>Users page</span> we can see all the users of the system. To create new
          <span className={`text-muted`}>Director</span> click on the <code>New User</code> button then fill Email, Password, and select roles
          <span className={`text-muted`}>login</span> and <span className={`text-muted`}>director</span> with <span className={`text-muted`}>Ctrl</span> button.
        </p>
        <p>Another way to create a new director user is a CLI command:</p>
        <pre><code>php artisan user:add admin@user.admin login,director password</code></pre>
        <div className="row">
          <div className="col-4">
            <ImageComponent
              pic={usersPic}
              alt={`Users Page`}
              class="img-fluid section-pic"
            />
          </div>
          <div className="col-4">
            <ImageComponent
              pic={createUserPic}
              alt={`Create User Page`}
              class="img-fluid section-pic"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BackofficePreview;
