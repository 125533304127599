import React from 'react';
import loginPic from "../../../../../../images/pages/docs/directorPreview/login.png";
import ImageComponent from "../../../../../Tools/Image/ImageComponent";

class Login extends React.Component {
  render() {
    return (
      <div className={'login'}>
        <h2>Login</h2>

        <p>
          With Homestead environment you could try default login: <b className={`text-muted`}>director@example.com</b> and
          password: <b className={`text-muted`}>secret</b>. Or you can create new user:
        </p>
        <pre><code>php artisan user:add director@example.com login,director password</code></pre>

        <div className="gallery">
          <div className="row">
            <div className="col-4">
              <ImageComponent
                pic={loginPic}
                alt={`Login Page`}
                class="img-fluid section-pic"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
