import React from 'react';
import './Configuration.css';
// import ScrollableAnchor, {configureAnchors} from "react-scrollable-anchor";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

class Configuration extends React.Component {
  render() {

    // configureAnchors({offset: -100, scrollDuration: 200});

    return (
      <div className="configuration-component">
        <h1><FormattedMessage
          id = "docs.web_server_configuration"
          defaultMessage="Web Server Configuration"
        /></h1>

        <ul className={`mc-list`}>
          <li>
            <Link to="/docs/installation#desc" className="blacklink"><FormattedMessage
              id = "docs.general_information"
              defaultMessage="General information"
            /></Link>
          </li>
          <li>
            <Link to="/docs/installation#env" className={`blacklink`}><FormattedMessage
              id = "docs.env_configuration_file"
              defaultMessage=".Env configuration file"
            /></Link>
          </li>
          <li>
            <Link to="/docs/installation#config" className={`blacklink`}><FormattedMessage
              id = "docs.generis_configuration_file"
              defaultMessage="Generis Configuration file"
            /></Link>
          </li>
        </ul>

        {/*<ScrollableAnchor id={"desc"}>*/}
          <h2>General information</h2>
        {/*</ScrollableAnchor>*/}
        <p>
          Configuration for the project can be split to 2 parts: <b>config</b> folder with <code>.env</code> file and
          <code>generis.conf.php</code> file; frontend configuration files from the <b>settings</b> folder, that needed
          for the development only, because compiled js already has this data. By default these folders placed
          to the ROOT project folder, but you could change this with servers parameter <code>APP_CONFIG_PATH</code>.
        </p>
        <p>
          Example for the <i>NGINX</i>:
        </p>
        <pre><code>
            fastcgi_param APP_CONFIG_PATH /new/path;
        </code></pre>


        {/*<ScrollableAnchor id={'config'}>*/}
          <h2>.Env Configuration file</h2>
        {/*</ScrollableAnchor>*/}
        <p><code>./config/.env</code> file is a configuration for the backend part of the MedCenter24 which based on the
        Laravel framework. List of parameters in this file depends on the modules which installed with this particular
        project.</p>


        {/*<ScrollableAnchor id={'env'}>*/}
          <h2>Configuration file</h2>
        {/*</ScrollableAnchor>*/}
        <p><code>./config/generis.conf.php</code> file is a configuration of the project. This file has 2 parameters only:
        </p>
        <pre><code>
          'env' => '../config/.env',
          'data' => '../data',
        </code></pre>
        <p>
          <code>env</code> - full path to the .env file configuration.
        </p>
        <p>
          <code>data</code> - full path to the directory for storing all the data (projects data and loaded by user).
        </p>
      </div>
    );
  }
}

export default Configuration;
