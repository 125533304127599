import React from 'react';
import './Homestead.css';

class Homestead extends React.Component {
  render() {
    return (
      <div className="homestead-component">
        <div className="content">
          <h1>Vagrant Box</h1>
          <p>Quick installation with vagrant box.</p>
          <p><a href="https://www.vagrantup.com/">Vagrant</a> provides a simple, elegant way to manage and provision Virtual Machines.</p>
          <p>You can find vagrant box on the Vagrant Cloud: <a href="https://app.vagrantup.com/medcenter24/boxes/homestead">https://app.vagrantup.com/medcenter24/boxes/homestead</a></p>
          <pre><code>
            vagrant init medcenter24/homestead \<br/>
            &nbsp;&nbsp;  --box-version 0.1<br />
            vagrant up
          </code></pre>
          <p>Please, add to your hosts file new records (to identify servers name)</p>
          <pre><code>
            127.0.0.1 backoffice.mc24 api.mc24 doctor.mc24 director.mc24
          </code></pre>
          <p>Also in the <b>Vagrantfile</b> you need to allow port 8080 or 8081 if 80 already taken by other process (<code>config.vm.network "forwarded_port", guest: 80, host: 8080</code>)</p>
          <p>Now you can go to the MedCenter24 local environment: <a href="http://backoffice.mc24">http://backoffice.mc24</a></p>
        </div>
      </div>
    );
  }
}

export default Homestead;
