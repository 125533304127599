import React from 'react';
import {Link} from "react-router-dom";
import './Footer.css';
import {FormattedMessage} from "react-intl";

class Footer extends React.Component {

    render() {
        const year = new Date().getFullYear();
        return (
            <>
                <footer className="footer">
                    <div className="container">
                        <div className="line"/>
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="block">
                                    <h4 className="bolder title"><FormattedMessage
                                        id="content.preview"
                                        defaultMessage="Preview"
                                    /></h4>
                                    <span className="text-muted"><FormattedMessage
                                        id="content.director_portal"
                                        defaultMessage="Director Portal"
                                    /></span>
                                    <br/>
                                    <span className="text-muted"><FormattedMessage
                                        id="content.doctor_portal"
                                        defaultMessage="Doctor Portal"
                                    /></span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="block">
                                    <h4 className="bolder title"><FormattedMessage
                                        id="content.ecosystem"
                                        defaultMessage="Ecosystem"
                                    /></h4>
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://github.com/medcenter24">GitHub</a>
                                    <br/>
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://medcenter24.slack.com"
                                    >Slack</a>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="block">
                                    <h4 className="bolder title"><FormattedMessage
                                        id="content.contacts"
                                        defaultMessage="Contacts"
                                    /></h4>
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="mailto:dev@medcenter24.com"
                                    >E-Mail</a>
                                    <br/>
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://medcenter24.slack.com/messages/CGXB07F4M"><FormattedMessage
                                        id="content.slack_channel"
                                        defaultMessage="Slack Channel"
                                    /></a>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="block">
                                    <h4 className="bolder title"><FormattedMessage
                                        id="content.about"
                                        defaultMessage="About"
                                    /></h4>
                                    <Link to="license"><FormattedMessage
                                        id="content.license"
                                        defaultMessage="License"
                                    /></Link>
                                    <br/>
                                    <Link to="privacy"><FormattedMessage
                                        id="content.privacy"
                                        defaultMessage="Privacy"
                                    /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4 text-muted">
                            <div className="col-12">
                                <div className="text-muted mt-2">© {year} MedCenter24.com</div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;
