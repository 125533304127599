import React from 'react';
import {FormattedMessage} from "react-intl";
import './License.css';

class License extends React.Component {
    render() {
        return (
            <>
                <div className="main-component p-top">
                    <div className="container">
                        <div className="row align-item-center">
                            <div className="col-12 text-block">
                                <FormattedMessage
                                    id="license.content"
                                    defaultMessage="License is GPL-2.0-only"
                                    values={{
                                        h1: (...chunks) => <h1>{chunks}</h1>,
                                        p: (...chunks) => <p>{chunks}</p>,
                                        strong: (...chunks) => <strong>{chunks}</strong>,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default License;
