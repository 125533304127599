import React from 'react';
import './Installation.css';
// import ScrollableAnchor from 'react-scrollable-anchor';
// import {configureAnchors} from 'react-scrollable-anchor';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

class Installation extends React.Component {
  render() {

    // configureAnchors({offset: -100, scrollDuration: 200});

    return (
      <div className="installation-component">
        <div className="row justify-content-center">
          <div className="col-12">
            <h1><FormattedMessage
              id="docs.installation"
              defaultMessage="Installation"
            /></h1>
            <ul className={`mc-list`}>
              <li>
                <Link to='/docs/installation#requirements' className="blacklink"><FormattedMessage
                  id="docs.server_requirements"
                  defaultMessage="Server Requirements"
                /></Link>
              </li>
              <li>
                <Link to="/docs/installation#installing" className={`blacklink`}><FormattedMessage
                  id="docs.installing_medcenter24"
                  defaultMessage="Installing MedCenter24"
                /></Link>
              </li>
              <li>
                <Link to="/docs/installation#configuration" className={`blacklink`}><FormattedMessage
                  id="docs.web_server_configuration"
                  defaultMessage="Web Server Configuration"
                /></Link>
              </li>
            </ul>

            {/*<ScrollableAnchor id={'requirements'}>*/}
              <h2><FormattedMessage
                id="docs.server_requirements"
                defaultMessage="Server Requirements"
              /></h2>
            {/*</ScrollableAnchor>*/}
            <ul className={`mc-list`}>
              <li>PHP >= 7.2.0</li>
              <li>
                <FormattedMessage
                  id="docs.php_extensions"
                  defaultMessage="PHP Extensions"
                />
                <ul className={`mc-list`}>
                  <li>
                    php-mbstring php-dom php-gd php-zip <span className="text-muted">(required by composer)</span>
                  </li>
                  <li>
                    php-imagick|php-gd <span className="text-muted">(based on the configuration)</span>
                  </li>
                  <li>
                    php-curl <span className="text-muted">(to use monolog and other protocols)</span>
                  </li>
                </ul>
              </li>
            </ul>

            {/*<ScrollableAnchor id={'installing'}>*/}
              <h2><FormattedMessage
                id="docs.installing_medcenter24"
                defaultMessage="Installing MedCenter24"
              /></h2>
            {/*</ScrollableAnchor>*/}
            <p>
              <a href="https://getcomposer.org/">Composer</a>. <FormattedMessage
              id="docs.installing_composer_desc"
              defaultMessage="MedCenter24 utilizes Composer to manage its dependencies. So, before
            using MedCenter24, make sure you have Composer installed on your machine."
            />
            </p>
            <p>
              <FormattedMessage
                id="docs.installing_quickest_way"
                defaultMessage="The quickest way to get the code on your environment"
              />:
            </p>
            <ol>
              <li>
                <code>git clone git@github.com:medcenter24/deploy-demo.git</code>
              </li>
              <li>
                <code>cd deploy-demo</code>
              </li>
              <li>
                <FormattedMessage
                  id="docs.installing_for_the_prod_mode"
                  defaultMessage="For the production mode"
                />:<br/>
                <pre><code>composer install --no-dev</code></pre>
                <FormattedMessage
                  id="docs.installing_for_the_dev_mode"
                  defaultMessage="for the development"
                />:<br/>
                <pre><code>composer install --prefer-source</code></pre>
              </li>
            </ol>

            <h3><FormattedMessage
              id="docs.installing_via_seed_file"
              defaultMessage="Via seed file"
            /></h3>
            <p>
              <code>php mcCore/artisan setup:seed --help</code>.
            </p>
            <p>
              <FormattedMessage
                id="content.example"
                defaultMessage="Example"
              />:
            </p>
            <pre><code>php mcCore/artisan setup:seed --force</code></pre>

            <h3><FormattedMessage
              id="docs.installing_via_setup_environment"
              defaultMessage="Via setup:environment command"
            /></h3>
            <p>
              <FormattedMessage
                id="docs.installation_step_by_step"
                defaultMessage="Step by step installation"
              />
            </p>
            <p>
              <code>php mcCore/artisan setup:environment --help</code>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Installation;
